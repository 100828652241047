
import { Vue, Component, Prop } from 'vue-property-decorator'
import $app from '@/plugins/modules'
import { IAttachment } from '@/modules/system/types';

@Component
export default class FileUpload extends Vue {
  @Prop(Array) readonly value: any;
  @Prop(String) readonly width: any;
  @Prop(String) readonly height: any;
  @Prop(String) readonly caption: any;
  @Prop(String) readonly maxFiles: any;
  @Prop(String) readonly accept: any;
  @Prop(Boolean) readonly storeThumbs: any;
  @Prop(Boolean) readonly disableRemove: any;
  @Prop(String) readonly background: any;

  focused: boolean = false;
  images: Record<string, string | ArrayBuffer | null> = {};

  get acceptIndex(): any {
    if (this.accept && this.accept.trim() !== 'image/*') {
      const index: any = {};
      const values: Array<string> = this.accept.split(',');
      values.forEach((item) => {
        if (item.trim().startsWith('.')) {
          index[item.trim().substring(1)] = true;
        } else {
          index[item.trim()] = true;
        }
      });
      return index;
    } else {
      return undefined;
    }
  }

  get full(): boolean {
    return this.maxFiles && Number(this.maxFiles) <= this.count;
  }

  get items(): Array<IAttachment> {
    return this.value || [];
  }

  get count(): number {
    return this.items.length;
  }

  get areaWidth(): string {
    return this.width || this.width === '' ? this.width : '100%';
  }

  get areaHeight(): string {
    return this.height || this.height === '' ? this.height : '50px';
  }

  get containerStyle(): string {
    return 'width: ' + this.areaWidth + '; ' +
      'height: ' + this.areaHeight + '; ' +
      'border-color: ' + (this.focused ? this.theme.primary : this.theme.text) + '; ';
  }

  get dropStyle(): string {
    return 'width: ' + (100 / (this.count + 1)) + '%; ' +
      'height: 100%; ' +
      (this.full ? 'display: none;' : '') +
      'border-color: ' + (this.focused ? this.theme.primary : this.theme.text) + '; ';
  }

  get theme(): any {
    const theme: any = this.$vuetify.theme;
    return this.$vuetify.theme.themes[theme.isDark ? 'dark' : 'light'];
  }

  itemStyle(item: IAttachment): string {
    let color = this.theme.text;

    if (item.uploaded > 0) {
      color = this.theme.success;
    }

    return 'width: ' + (100 / (this.count + (this.full ? 0 : 1))) + '%; ' +
      'cursor: pointer; ' +
      'height: 100%; ' +
      'margin-right: 5px; ' +
      'border-color: ' + color + '; ';
  }

  enterDrag(e: any) {
    e.preventDefault();
    this.focused = true;
  }

  exitDrag(e: any) {
    e.preventDefault();
    this.focused = false;
  }

  drop(ev: any) {
    ev.preventDefault();
    this.focused = false;
    let files: Array<File> = [];
    if (ev.dataTransfer.items) {
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        if (ev.dataTransfer.items[i].kind === 'file') {
          files.push(ev.dataTransfer.items[i].getAsFile());
        }
      }
    } else {
      files = ev.dataTransfer.files;
    }
    this.attachAll(files);
  }

  attachAll(files: Array<File>) {
    const newItems: Array<IAttachment> = [];

    for (let i = 0; i < files.length; i++) {
      if (this.maxFiles && Number(this.maxFiles) <= newItems.length) {
        break;
      }
      this.attach(files[i], newItems);
    }

    this.$emit('input', this.items.concat(newItems));
    this.$emit('upload', newItems);
    const self = this;

    for (let i = 0; i < newItems.length; i++) {
      if (newItems[i].image && !this.images[newItems[i].name]) {
        if (newItems[i].file) {
          const reader = new FileReader()
          reader.readAsDataURL(newItems[i].file!);
          reader.onloadend = () => {
            Vue.set(self.images, newItems[i].name, reader.result);
            if (this.storeThumbs || this.storeThumbs === '') {
              newItems[i].thumb = reader.result as string;
              this.$emit('thumb', newItems[i]);
            }
          }
        }
      }
    }
  }

  attach(file: File, newItems: Array<IAttachment>) {
    for (let i = 0; i < this.items.length; i++) {
      if (file.name === this.items[i].name) {
        return;
      }
    }

    const attach: IAttachment = {
      id: null,
      mime: file.type,
      name: file.name,
      ext: file.name.split('.').pop()!,
      file: file,
      companyId: null,
      userId: null,
      state: 'l',
      created: null,
      info: null,
      uploaded: 0,
      thumb: null,
      url: null,
      image: file && file.type.split('/')[0] === 'image'
    }

    if (this.accept) {
      if (this.accept.trim() === 'image/*' && !attach.image) {
        return;
      } else if (this.acceptIndex && !this.acceptIndex[attach.mime] && !this.acceptIndex[attach.ext]) {
        return;
      }
    }

    newItems.push(attach);
  }

  remove(attach: IAttachment, index: number) {
    if (!this.disableRemove && this.disableRemove !== '') {
      const newItems: Array<IAttachment> = $app.clone(this.items);
      newItems.splice(index, 1);

      if (this.images[attach.name]) {
        const newImages: Record<string, string | ArrayBuffer | null> = {}
        for (const key in this.images) {
          if (key !== attach.name) {
            newImages[key] = this.images[key]
          }
        }
        this.images = newImages;
      }

      this.$emit('input', newItems);
    } else {
      this.$emit('open', attach);
    }
  }

  openFiles(ev: any) {
    this.attachAll(ev.target.files);
    ev.target.value = ''
  }
}
